<template>
  <div class="leasing-strategy">
    <template v-if="hasPermission">
      <div class="common-shadow-panel">
        <h2 class="w-title-gradients">策略概览</h2>
        <ul class="common-data-list">
          <li>
            <!-- <h3>{{['标杆', '标准', '非标'][overviewInfo.brandLevels[0].type-1]}}品牌目标占比</h3> -->
            <h3>{{overviewInfo.brandLevels[0].type}}级品牌目标占比</h3>
            <p class="number-p">
              <span class="number-span">{{overviewInfo.brandLevels[0].value}}</span>
              %
            </p>
            <ul class="data-item">
              <li
                v-for="(item,index) in overviewInfo.brandLevels"
                :key="index"
                v-show="index !== 0"
              >
                <!-- {{['标杆', '标准', '非标'][item.type-1]}}品牌 <span>{{item.value}}%</span> -->
                {{item.type + (index > 1 ? '': '级')}}品牌 <span>{{item.value}}%</span>
              </li>
            </ul>
          </li>
          <li>
            <h3>主力店规划面积</h3>
            <p class="number-p">
              <span class="number-span">
                {{overviewInfo.mainBerthTypes[0].value}}
              </span>
              ㎡
            </p>
            <p class="data-item">
              次主力店规划面积 <span>{{overviewInfo.mainBerthTypes[1].value}} ㎡ </span>
            </p>
          </li>
          <li>
            <h3>业态规划面积</h3>
            <p class="number-p">
              <span class="number-span">
                {{overviewInfo.industryPlanArea}}
              </span>
              ㎡
            </p>
            <p class="data-item">业态数量 <span>{{overviewInfo.industryNum}} </span></p>
          </li>
        </ul>
      </div>
      <div class="common-shadow-panel">
        <h2 class="w-title-gradients fl">品牌策略</h2>
        <span
          class="w-title-gradients-right pointer fr"
          v-if="hasDataBrand"
          @click="showDrawerStrategy(0, 1)"
        >
          <i class="icon-edit-new"></i>
          编辑
        </span>
        <div class="clearfix"></div>
        <ul
          class="common-block-list brand-decision"
          v-if="hasDataBrand"
        >
          <!-- <li
            v-for="item in brandCardList"
            :key="item.brandLevelType"
          >
            <h4>{{['标杆', '标准', '非标'][item.brandLevelType-1]}}品牌目标占比</h4>
            <p class="num">{{item.brandLevelValue}}%</p>
            <ul>
              <li
                v-for="(ele,i) in item.brandGradeType"
                :key="i"
              >
                <span>{{item.brandGradeValue[i] | notEmpty}}%</span>
                {{['A', 'B', 'C', 'D'][i]}}级
              </li>
            </ul>
          </li> -->
          <li
            v-for="(item, index) in brandCardList"
            :key="index+item.brandLevelType"
          >
            <h4>{{item.brandLevelType}}级品牌<span style="white-space: nowrap">目标占比</span></h4>
            <p class="num">
              {{item.brandLevelValue}}<span>%</span>
            </p>
            <i class="iconfont">{{['&#xe6c5;','&#xe6c1;','&#xe6c4;','&#xe6c2;','&#xe6c3;'][index]}}</i>
          </li>
        </ul>
        <div
          class="no-recommend-brand"
          style="height:279px"
          v-else
        >
          <img
            style="margin-top: 40px;"
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>当前租赁决策文件未配置品牌策略</p>
          <div class="button-check-ew mt10">
            <span
              class="button-basic"
              @click="showDrawerStrategy(0, 0)"
            >去添加</span>
          </div>
        </div>
      </div>
      <div class="common-shadow-panel">
        <h2 class="w-title-gradients fl">业态策略</h2>
        <span
          class="w-title-gradients-right pointer"
          @click="showDrawerStrategy(1, 1)"
          v-if="hasDataFormat"
        >
          <i class="icon-edit-new"></i>
          编辑
        </span>
        <div class="clearfix"></div>
        <el-table
          v-if="hasDataFormat"
          class="tbs-black"
          :data="tbsFormat"
        >
          <el-table-column
            prop="industry"
            label="业态名称"
            width=""
          >
          </el-table-column>
          <el-table-column
            prop="planArea"
            label="规划面积(㎡)"
            width=""
          >
          </el-table-column>
          <el-table-column
            prop="areaRate"
            label="面积占比(%)"
          >
          </el-table-column>
          <el-table-column
            prop="standardRent"
            :label="`策略租金${unit&&'('+unit+')'}`"
          >
          </el-table-column>
          <el-table-column
            prop="rentYear"
            label="租赁年限(年)"
          >
          </el-table-column>
          <el-table-column
            prop="rentFreeTime"
            label="免租时长(天)"
          >
          </el-table-column>
          <el-table-column
            prop="rentIncreasingRate"
            label="租金增长率(%)"
          >
          </el-table-column>
          <el-table-column
            prop="managementCost"
            :label="`${user.topuid === 6467 ? '物业' : '综合'}管理费${unit&&'('+unit+')'}`"
          >
          </el-table-column>
        </el-table>
        <div
          class="no-recommend-brand"
          style="height:279px"
          v-else
        >
          <img
            style="margin-top: 40px;"
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>当前租赁决策文件未配置业态策略</p>
          <div class="button-check-ew mt10">
            <span
              class="button-basic"
              @click="showDrawerStrategy(1, 0)"
            >去添加</span>
          </div>
        </div>
      </div>
      <div class="common-shadow-panel">
        <h2 class="w-title-gradients fl">主次力店策略</h2>
        <span
          class="w-title-gradients-right pointer"
          v-if="hasDataStore"
          @click="showDrawerStrategy(2, 1)"
        >
          <i class="icon-edit-new"></i>
          编辑
        </span>
        <div class="clearfix"></div>
        <el-table
          v-if="hasDataStore"
          class="tbs-black"
          :data="tbsStore"
        >
          <el-table-column
            prop="mainBerthType"
            label="主次力店"
            width=""
          >
          </el-table-column>
          <el-table-column
            prop="planArea"
            label="规划面积(㎡)"
            width=""
          >
          </el-table-column>
          <el-table-column
            prop="areaRate"
            label="面积占比(%)"
          >
          </el-table-column>
          <el-table-column
            prop="standardRent"
            :label="`策略租金${unit&&'('+unit+')'}`"
          >
          </el-table-column>
          <el-table-column
            prop="rentYear"
            label="租赁年限(年)"
          >
          </el-table-column>
          <el-table-column
            prop="rentFreeTime"
            label="免租时长(天)"
          >
          </el-table-column>
          <el-table-column
            prop="rentIncreasingRate"
            label="租金增长率(%)"
          >
          </el-table-column>
          <el-table-column
            prop="managementCost"
            :label="`${user.topuid === 6467 ? '物业' : '综合'}管理费${unit&&'('+unit+')'}`"
          >
          </el-table-column>
        </el-table>
        <div
          class="no-recommend-brand"
          style="height:279px"
          v-else
        >
          <img
            style="margin-top: 40px;"
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>当前租赁决策文件未配置主次力店策略</p>
          <div class="button-check-ew mt10">
            <span
              class="button-basic"
              @click="showDrawerStrategy(2, 0)"
            >去添加</span>
          </div>
        </div>
      </div>
    </template>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { powerAuth } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'leasingStrategy',
  data () {
    return {
      unit: '',
      hasPermission: true,
      // decisionsInfo: {},
      overviewInfo: {
        brandLevels: [
          {
            type: 1,
            value: '-'
          },
          {
            type: 2,
            value: '-'
          },
          {
            type: 3,
            value: '-'
          }
        ],
        industryNum: 0,
        industryPlanArea: '',
        mainBerthTypes: [
          {
            type: 1,
            value: '-'
          },
          {
            type: 2,
            value: '-'
          }
        ]
      },
      rentUnitList: ['', '元/日/㎡', '元/月/㎡'],
      drawer: false,
      drawerType: 1,
      drawerIndex: 0,
      hasDataBrand: true,
      hasDataFormat: true,
      hasDataStore: true,
      formFormatItemKey: 0,
      brandCardList: [],
      tbsStore: [],
      tbsFormat: [],
      formBrand: [],
      formFormat: [],
      formStore: [],
      brandLevelList: ['S级', 'A级', 'B级', 'C级', 'D级']
    }
  },
  computed: {
    wrapHeight: () => {
      return document.documentElement.clientHeight - 145 + 'px'
    },
    ...mapGetters({
      user: 'user'
    })
  },
  components: {},
  filters: {
    notEmpty: (val) => {
      if (!val && val !== 0) {
        return '-'
      } else {
        return val
      }
    }
  },
  props: {
    projectId: {
      type: Number
    },
    decisionId: {
      type: Number
    }
  },
  watch: {
    projectId (newValue, oldValue) {
      console.log('projectId', this.projectId)
    }
  },
  mounted () {
    if (powerAuth(211, 'show')) {
      this.handleData()
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    handleData () {
      this.getDataOverview()
      this.getDataBrand()
      this.getDataFormat()
      this.getDataStore()
      // this.getDecision()
    },
    showDrawerStrategy (index, type) {
      if (!powerAuth(211, 'edit')) {
        this.$message({
          type: 'warning',
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return
      }
      this.$emit('showDrawer', { index, type })
    },
    // getDecision () {
    //   this.axios.get(api.handleDecisions + this.decisionId, {}).then(res => {
    //     if (res.data.code === 0) {
    //       const resData = res.data.data
    //       this.decisionsInfo = resData
    //     }
    //   }, rej => {
    //     console.log(rej)
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    getDataStore () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.getStoreList, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resDataxcgdfg', resData)
          if (resData && resData.length) {
            this.hasDataStore = true
            this.tbsStore = resData
          } else {
            this.hasDataStore = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataFormat () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.getFormatDecisionsList, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          if (resData && resData.length) {
            this.hasDataFormat = true
            this.tbsFormat = resData
          } else {
            this.hasDataFormat = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleClose () {
      this.drawer = false
      if (this.drawerIndex === 1) {
        // this.resetFormFormat()
      }
      if (this.drawerIndex === 2) {
        this.getDataStore()
      }
    },
    getDataBrand () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.handleBrandDecisions, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          if (resData.brandLevels && resData.brandLevels.length) {
            this.hasDataBrand = true
            this.brandCardList = resData.brandLevels
          } else {
            this.hasDataBrand = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataOverview () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.getFormatDecisionsOverview, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.unit = resData.rentUnit ? ['', '元/日/㎡', '元/月/㎡'][resData.rentUnit] : ''
          if (resData.brandLevels && resData.brandLevels.length) {
            this.overviewInfo.brandLevels = resData.brandLevels.map(item => {
              const tempObj = {
                type: item.type
              }
              if (!item.value && item.value !== 0) {
                tempObj.value = '-'
              } else {
                tempObj.value = item.value
              }
              return tempObj
            })
          } else {
            this.overviewInfo.brandLevels = [
              {
                type: 1,
                value: '-'
              },
              {
                type: 2,
                value: '-'
              },
              {
                type: 3,
                value: '-'
              }
            ]
          }
          if (resData.mainBerthTypes && resData.mainBerthTypes.length) {
            this.overviewInfo.mainBerthTypes = resData.mainBerthTypes.map(item => {
              const tempObj = {
                type: item.type
              }
              if (!item.value && item.value !== 0) {
                tempObj.value = '-'
              } else {
                tempObj.value = item.value
              }
              return tempObj
            })
          } else {
            this.overviewInfo.mainBerthTypes = [
              {
                type: 1,
                value: '-'
              },
              {
                type: 2,
                value: '-'
              }
            ]
          }
          if (!resData.industryNum && resData.industryNum !== 0) {
            this.overviewInfo.industryNum = '-'
          } else {
            this.overviewInfo.industryNum = resData.industryNum
          }
          if (!resData.industryPlanArea && resData.industryPlanArea !== 0) {
            this.overviewInfo.industryPlanArea = '-'
          } else {
            this.overviewInfo.industryPlanArea = resData.industryPlanArea
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
    // focusFreeTime (key, index, i) { // 暂时没用
    //   let value = ''
    //   if (key === 0) {
    //     value = this.formFormat[index].rentList[i].phaseTime
    //   }
    //   if (key === 1) {
    //     value = this.formStore[index].rentList[i].phaseTime
    //   }
    //   if (!value) {
    //     this.$message({
    //       type: 'warning',
    //       message: '请先选择阶段时间'
    //     })
    //     this.$refs[`phaseTime-${index}-${i}`][0].focus()
    //   }
    // },
  }
}
</script>

<style lang="stylus" scoped>
.common-data-list
  li
    h3
      margin-top 22px
    ul
      li
        width auto
        height auto
        border none
        margin 0 12px
        position relative
        top 1px
.leasing-strategy>>>
  .brand-decision
    margin 40px 100px
  .tbs-black
    width auto
    margin 2px 24px 40px 24px
    box-sizing border-box
    td
      color #808191 !important
      padding 17px 0
    th
      padding 20px 0
</style>
